const Support = () => {
    return (
        <>
            {/* Start slider */}
            <section className="slider-sec slider-pages">
                <div className="slider-item">
                    <img src="uploads/slider/help.png" />
                </div>
            </section>
            {/* Start Breadcrumb */}
            <section className="breadcrumb-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">الصفحة الرئيسية</a>
                                    </li>
                                    <span>&ensp;|&ensp; </span>

                                    <li className="breadcrumb-item active">مركز المساعدة</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Breadcrumb */}
            {/* Start Customers Section */}
            <section className="customers-sec pad-50">
                <div className="container">
                    <div className="sec-title">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                <h4>مركز المساعدة</h4>
                                <p />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-4 col-sm-12 col-12 align-self-center">
                            <img
                                src="/uploads/support.jpg"
                                alt="يمكننا مساعدتك مع نظام  Birdsys"
                            />
                        </div>
                        <div className="col-lg-7 col-md-8 col-sm-12 col-12 align-self-center">
                            <div className="about-txt">
                                <h1>يمكننا مساعدتك مع نظام Birdsys</h1>
                                {/* <h1>CPE System</h1> */}
                                <blockquote>
                                    <p>
                                        يسعدنا تقديم المساعدة لمستخدمي نظام بيرد ، وذلك من خلال العديد
                                        أنظمة المساعدة المتوفرة بخدمة العملاء وفنيين النظام.
                                    </p>
                                    <p>
                                        الاتصال بالرقم الموحد: يمكنك الاتصال بالرقم الموحد والتواصل
                                        تليفونيا مع خدمة العملاء لعرض نوع المساعدة المطلوبة، وسيتم الرد
                                        عليك بأفضل وسائل الدعم وتقديم المساعدة.
                                    </p>
                                    <p>
                                        المساعدة الفورية: وهي عبارة عن طلب المساعدة من خلال المحادثات
                                        النصية والفورية مع خدمة العملاء من خلال الموقع الالكتروني، ثم
                                        إختيار نوع الدعم أو المساعدة المطلوبة وتحويلها فوراً للمركز
                                        التقني إذا إحتاج الأمر إلى ذلك.
                                    </p>
                                    <p>
                                        إرسال طلب: فهو عبارة عن إرسال مذكرة للمساعدة وعرض الطلب من خلال
                                        تطبيق الواتساب وتحويل الطلب إلى الدعم الفوري وحل الأمر بما يضمن
                                        متابعة سير عملك دون حدوث أخطاء.
                                    </p>
                                    <p>
                                        وبذلك يكون فريق نظام بيرد قد وفر جميع الطرق الملائمة لمساعدتك
                                        وضمان عدم توقف سير العمل أو حدوث أخطاء.
                                        <br />
                                    </p>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                        <ul
                            className="nav nav-pills nav-justified mb-5 help-center-tabs"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active "
                                    id="pills-2-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-2"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-2"
                                    aria-selected="true"
                                >
                                    <img
                                        src="../uploads/help/nk8en9tShIfapTaWkn39H6SVca5XcngI.png"
                                        alt="الإتصال بالرقم الموحد"
                                    />
                                    <span>الإتصال بالرقم الموحد</span>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link  "
                                    id="pills-3-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-3"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-3"
                                    aria-selected="true"
                                >
                                    <img
                                        src="../uploads/help/ndwXw3WBaLhEPM7abM6Oi3JsdhoZMrds.png"
                                        alt="المساعدة الفورية"
                                    />
                                    <span>المساعدة الفورية</span>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link  "
                                    id="pills-4-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-4"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-4"
                                    aria-selected="true"
                                >
                                    <img
                                        src="../uploads/help/OFLVUz6BFMafLPMoH7CZs3iiAhghhBxt.png"
                                        alt="طلب المساعدة"
                                    />
                                    <span>طلب المساعدة</span>
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-2"
                                role="tabpanel"
                                aria-labelledby="pills-2-tab"
                            >
                                <blockquote className="text-center mb-0">
                                    <p>
                                        الاتصال بالرقم الموحد: يمكنك الاتصال بالرقم الموحد والتواصل
                                        تليفونيا مع خدمة العملاء لعرض نوع المساعدة المطلوبة، وسيتم الرد
                                        عليك بأفضل وسائل الدعم وتقديم المساعدة.
                                    </p>
                                </blockquote>
                            </div>
                            <div
                                className="tab-pane fade show "
                                id="pills-3"
                                role="tabpanel"
                                aria-labelledby="pills-3-tab"
                            >
                                <blockquote className="text-center mb-0">
                                    <p>
                                        المساعدة الفورية: وهي عبارة عن طلب المساعدة من خلال المحادثات
                                        النصية والفورية مع خدمة العملاء من خلال الموقع الالكتروني، ثم
                                        إختيار نوع الدعم أو المساعدة المطلوبة وتحويلها فوراً للمركز
                                        التقني إذا إحتاج الأمر إلى ذلك.
                                    </p>
                                </blockquote>
                            </div>
                            <div
                                className="tab-pane fade show "
                                id="pills-4"
                                role="tabpanel"
                                aria-labelledby="pills-4-tab"
                            >
                                <blockquote className="text-center mb-0">
                                    <p>
                                        طلب المساعدة : فهو عبارة عن إرسال طلب للمساعدة وعرض الطلب من
                                        خلال تطبيق الواتساب أو الإيميل المخصص للدعم والمساعدة، ثم تحويل
                                        الطلب إلى الدعم الفوري وحل الأمر بما يضمن متابعة سير عملك دون
                                        حدوث أخطاء.
                                    </p>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Support;