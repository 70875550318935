import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="top-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="site-info">
                                    <img src="logo.png" className="mb-4" alt="Site Logo" />
                                    <p className="mb-4">
                                        يمثل نظام ( Birdsys ) واحدًا من نظم تخطيط الموارد الهامة للشركات، فهو يتيح
                                        مجموعة أدوات متكاملة للمساعدة في أداء أعمال شركتك، كما يوفّر النظام حلولًا رقمية لأصحاب
                                        الأعمال ، نظام بيرد معتمد من هيئة الزكاة والضريبة والجمارك في حلول الفوترة الالكترونية
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-5 hide-mobile">
                                <div className="footer-title">
                                    <h4>أهم الروابط</h4>
                                    <hr />
                                </div>
                                <ul className="footer-menu">
                                    <li><a href='/#'>الصفحة الرئيسية</a></li>
                                    <li><Link to='/about-system#'>من نحن</Link></li>
                                    <li><Link to='/plan#'>الأسعار</Link></li>
                                    <li><Link to='/help'>مركز المساعدة</Link></li>
                                    <li><Link to='/blog'>المدونة</Link></li>
                                    <li><Link to='/faq'>الأسئلة الشائعة</Link></li>
                                    <li><Link to='/contact-us'>تواصل معنا</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-5 text-start">
                                <div className="form-group subscribe-div">
                                    <h5 className="text-center">اشترك في القائمة البريدية سيصلك كل جديد</h5>
                                    <form id="w3" className="row" name="maillist22" action="" method="post">
                                        <div className="col-lg-8 col-md-12 col-sm-8 col-8">
                                            <input type="text" id="maillist-email" className="form-control" name="Maillist[email]" placeholder="البريد الالكتروني" />
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-4 col-4 sub-btn">
                                            <button type="submit" className="btn btn-primary small-btn">الإشتراك</button>
                                        </div>
                                        <p id="message" className="help-block help-block-error mb-0"></p>
                                        <p id="message-s" className="mb-0"></p>
                                    </form>
                                    <div className="row"></div>
                                </div>
                                <div className="row justify-content-center align-items-center">
                                    <img src="frontend/assets/image/zakat.png" className="mt-2 vat-logo" alt="Zakat Logo" />
                                    <img src="frontend/assets/image/fatora-m.png" className="mt-2 vat-logo2" alt="Fatora Logo" />
                                    <div className="col-8"></div>
                                    <div className="col-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div className="container">
                        <div className="row">
                            <p className="copyright text-center">جميع الحقوق محفوظة Birdsys &copy;2024</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
