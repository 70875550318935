const Blog = () => {
    return (

        <div className="site-content">
            {/* Start Breadcrumb */}
            <section className="breadcrumb-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">الصفحة الرئيسية</a>
                                    </li>
                                    <span>&ensp;|&ensp; </span>
                                    <li className="breadcrumb-item active">المدونة</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Breadcrumb */}
            {/* Department */}
            <section className="dept-page-sec pad-50">
                <div className="container">
                    <div className="sec-title mb-3">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                <h4>المدونة</h4>
                                <p> بيرد  - الابتكار الامثل </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="feature-block dept_desc_blog">
                                <div className="blog_img">
                                    <img
                                        className="image-topic"
                                        src="uploads/depart_system/invo.jpg"
                                        alt="ماهو دعم الفوترة الالكترونية؟ وكيفية الحصول عليها؟"
                                    />
                                </div>
                                <div className="about-txt p-3">
                                    <p>17 أكتوبر 2023</p>
                                    <h4>
                                        ماهو دعم الفوترة الالكترونية؟ وكيفية الحصول عليها؟
                                    </h4>
                                    <blockquote className="line-clamp">
                                        تعتبر الفوترة الالكترونية من التقنيات الحديثة التي تهدف إلى
                                        تحويل عمليات الفوترة التقليدية إلى عمليات إلكترونية سهلة ومرنة،
                                        حيث توفر...
                                    </blockquote>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="feature-block dept_desc_blog">
                                <div className="blog_img">
                                    <img
                                        className="image-topic"
                                        src="uploads/depart_system/pay.jpg"
                                        alt="الفاتورة الإلكترونية وأهم الخطوات الأساسية لتفعيلها |  بيرد  سوفت "
                                    />
                                </div>
                                <div className="about-txt p-3">
                                    <p>17 أكتوبر 2023</p>
                                    <h4>
                                        الفاتورة الإلكترونية وأهم الخطوات الأساسية لتفعيلها |  بيرد
                                        سوفت{" "}
                                    </h4>
                                    <blockquote className="line-clamp">
                                        يشهد العصر الحديث تطوراً كبيراً في مجال التكنولوجيا والاتصالات،
                                        حيث أصبحت الوسائل الإلكترونية جزءاً لا يتجزأ من حياتنا اليومية،
                                        وتُعتبر...
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="feature-block dept_desc_blog">
                                <div className="blog_img">
                                    {" "}
                                    <img
                                        className="image-topic"
                                        src="uploads/depart_system/sys.jpg"
                                        alt=" أهم شروط فتح متجر الكتروني في السعودية 2023 |  بيرد  سوفت"
                                    />
                                </div>
                                <div className="about-txt p-3">
                                    <p>17 أكتوبر 2023</p>
                                    <h4>
                                        أهم شروط فتح متجر الكتروني في السعودية 2023 |  بيرد  سوفت
                                    </h4>
                                    <blockquote className="line-clamp">
                                        في هذا العصر الرقمي المتطور، أصبح فتح متجر إلكتروني واحدًا من
                                        الخيارات المُثيرة للاهتمام للأفراد والشركات على حد سواء، حيث...
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Blog;