import React, { useState } from 'react';

const Plan = () => {
    const [visibleRows, setVisibleRows] = useState({});

    const toggleRow = (id) => {
        setVisibleRows((prevVisibleRows) => ({
            ...prevVisibleRows,
            [id]: !prevVisibleRows[id],
        }));
        const icon = document.querySelector(`.change_${id}`);
        if (icon) {
            icon.classList.toggle('fa-plus');
            icon.classList.toggle('fa-minus');
        }
    };

    return (
        <div className="site-content">
            {/* Start slider */}
            <section className="slider-sec slider-pages">
                <div className="slider-item">
                    <img src="uploads/slider/plan.png" alt="Slider" />
                </div>
            </section>
            {/* Start Breadcrumb */}
            <section className="breadcrumb-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">الصفحة الرئيسية</a></li>
                                    <span>&ensp;|&ensp; </span>
                                    <li className="breadcrumb-item active">خطط الأسعار</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Breadcrumb */}

            {/* Department */}
            <section className="prices-sec padt-50">
                <div className="container">
                    <div className="sec-title">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                <h4>خطط الأسعار</h4>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="table-responsive">
                                <table className="table table-borderless table-striped price-table">
                                    <tbody>
                                        <tr>
                                            <td className="fix_width"></td>
                                            <td>
                                                <div className="backage-block">
                                                    <div className="backage-title">
                                                        <h4>الأساسية</h4>
                                                    </div>
                                                    <div className="backage-price shadow-sm">
                                                        <p><span className="price">1020</span> ريال/ سنوياً</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="backage-block">
                                                    <div className="backage-title">
                                                        <h4>المتقدمة </h4>
                                                    </div>
                                                    <div className="backage-price shadow-sm">
                                                        <p><span className="price">1800</span> ريال/ سنوياً</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="backage-block">
                                                    <div className="backage-title">
                                                        <h4>المميزة </h4>
                                                    </div>
                                                    <div className="backage-price shadow-sm">
                                                        <p><span className="price">2150</span> ريال/ سنوياً</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="backage-block">
                                                    <div className="backage-title">
                                                        <h4>الشركات </h4>
                                                    </div>
                                                    <div className="backage-price shadow-sm">
                                                        <p><span className="price">Contact us</span> </p>
                                                        <p>تواصل معنا لافضل الاسعار</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr onClick={() => toggleRow(108)}>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام المبيعات
                                                    </div>
                                                    <div className="col-3">
                                                        <i className={`fa ${visibleRows[108] ? 'fa-minus' : 'fa-plus'} change_108 icon_font`}></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="about-system.html" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr id="hide_108" style={{ display: visibleRows[108] ? 'table-row' : 'none' }}>
                                            <td>
                                                <p>- إدارة العملاء العملاء </p>
                                                <p>- إدارة فريق المبيعات</p>
                                                <p>- عروض الأسعار</p>
                                                <p>- فواتير المبيعات ( الفوترة الالكترنية )</p>
                                                <p>- الإشعارات الدائنة ( مرتجعات المبيعات )</p>
                                                <p>- سندات العملاء</p>
                                                <p>- أوامر التسليم</p>
                                                <p>- تقارير المبيعات</p>
                                                <p>- الفواتير الضريبية</p>
                                                <p>- الاقرار الضريبي</p>
                                                <p>لمزيد من المعلومات <strong><a href="about-system/Birdsys-for-sales.html" rel="noopener noreferrer"><span style={{ color: 'rgb(192, 80, 77)' }}></span><span style={{ backgroundColor: 'rgb(255, 230, 148)' }}></span>انقر هنا</a></strong></p>
                                                <p>لمشاهدة فيويو شرح النظام <a href="https://www.youtube.com/watch?v=IZbllLgpEqo&t=398s" rel="noopener noreferrer">انقرهنا</a></p>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr onClick={() => toggleRow(109)}>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام المشتريات
                                                    </div>
                                                    <div className="col-3">
                                                        <i className={`fa ${visibleRows[109] ? 'fa-minus' : 'fa-plus'} change_109 icon_font`}></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="about-system.html" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr id="hide_109" style={{ display: visibleRows[109] ? 'table-row' : 'none' }}>
                                            <td>
                                                <p>- الموردين</p>
                                                <p>- قوائم أسعار المورد</p>
                                                <p>- طلبات الشراء</p>
                                                <p>- فواتير المشتريات</p>
                                                <p>- فواتير المصروفات</p>
                                                <p>- الإشعارات المدينة ( مرتجع المشتريات )</p>
                                                <p>- سندات الموردين</p>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr onClick={() => toggleRow(110)}>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام المخزون والمستودعات
                                                    </div>
                                                    <div className="col-3">
                                                        <i className={`fa ${visibleRows[110] ? 'fa-minus' : 'fa-plus'} change_110 icon_font`}></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="about-system.html" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr id="hide_110" style={{ display: visibleRows[110] ? 'table-row' : 'none' }}>
                                            <td>
                                                <p>- المستودعات</p>
                                                <p>- المواقع والأرفف</p>
                                                <p>- فئات المنتجات</p>
                                                <p>- قائمة المنتجات</p>
                                                <p>- وحدات القياس</p>
                                                <p>- تجميع الأصناف</p>
                                                <p>- تفكيك الأصناف</p>
                                                <p>- التسوية المخزنية</p>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr onClick={() => toggleRow(111)}>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام الحسابات
                                                    </div>
                                                    <div className="col-3">
                                                        <i className={`fa ${visibleRows[111] ? 'fa-minus' : 'fa-plus'} change_111 icon_font`}></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="about-system.html" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr id="hide_111" style={{ display: visibleRows[111] ? 'table-row' : 'none' }}>
                                            <td>
                                                <p>- الإشعارات الدائنة</p>
                                                <p>- الإشعارات المدينة</p>
                                                <p>- جرد الصندوق</p>
                                                <p>- سندات الصرف</p>
                                                <p>- شجرة الحسابات</p>
                                                <p>- مراكز التكلفة</p>
                                                <p>- قيود اليومية</p>
                                                <p>- التسوية البنكية</p>
                                                <p>- سندات التحويل</p>
                                                <p>- سندات القبض</p>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr onClick={() => toggleRow(113)}>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام نقاط البيع POS
                                                    </div>
                                                    <div className="col-3">
                                                        <i className={`fa ${visibleRows[113] ? 'fa-minus' : 'fa-plus'} change_113 icon_font`}></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="about-system.html" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr id="hide_113" style={{ display: visibleRows[113] ? 'table-row' : 'none' }}>
                                            <td>
                                                <p>- الطلبات</p>
                                                <p>- الجلسات</p>
                                                <p>- المدفوعات</p>
                                                <p>- العملاء</p>
                                                <p>- فئات المنتجات</p>
                                                <p>- المنتجات</p>
                                                <p>- تقارير الطلبات</p>
                                                <p>- تفاصيل المبيعات</p>
                                                <p>- طرق السداد</p>
                                                <p>- قوائم الأسعار</p>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نقاط البيع
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="about-system.html" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام الموارد البشرية HR
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        عدد الموظفين
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام CRM
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        نظام إدارة المشاريع PM
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        المتجر الالكتروني
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-window-minimize"></i></a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="row">
                                                    <div className="col-9">
                                                        عدد السجلات
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer">1</a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer">1</a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer">1</a>
                                            </td>
                                            <td className="text-center">
                                                <a href="#" rel="noopener noreferrer"><i className="far fa-check-circle"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>

                                            <td>
                                                <div class="text-center mt-4 mb-5">
                                                    <a type="button" class="btn btn-light custom-btn d-inline" href="plan/order?plan_id=13" role="modal-remote">اطلب الان</a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center mt-4 mb-5">
                                                    <a type="button" class="btn btn-light custom-btn d-inline" href="plan/order?plan_id=14" role="modal-remote">اطلب الان</a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center mt-4 mb-5">
                                                    <a type="button" class="btn btn-light custom-btn d-inline" href="plan/order?plan_id=12" role="modal-remote">اطلب الان</a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center mt-4 mb-5">
                                                    <a type="button" class="btn btn-light custom-btn d-inline" href="plan/order?plan_id=15" role="modal-remote">اطلب الان</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal */}
                <div className="modal fade" id="ajaxCrudModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <style>
                {`
                .hide_all {
                    display: none;
                }

                .icon_font {
                    font-size: 12px !important;
                    padding: 5px;
                    color: #b1b1b1;
                    cursor: pointer;
                }

                .fix_width {
                    min-width: 200px;
                    display: block;
                }

                .price-table td .fa-window-minimize {
                    color: #b1b1b1;
                }
                `}
            </style>
        </div>
    );
};

export default Plan;
