import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import Layout from "./Layout";
import Home from "./Home";
import AboutSys from "./AboutSys";
import Plan from "./Plan";
import Support from "./Support";
import Faq from "./Faq";
import Contact from "./Contact"
import BestInSys from "./BestInSys";
import Blog from "./Blog";
import ScrollToTop from "./component/ScrollToTop";
function App() {
  return (
    <>
      <Router>
        <Layout>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact>
              <Home></Home>
            </Route>
            <Route path="/about-system">
              <AboutSys></AboutSys>
            </Route>
            <Route path="/plan">
              <Plan></Plan>
            </Route>
            <Route path="/help">
              <Support></Support>
            </Route>
            <Route path="/faq">
              <Faq></Faq>
            </Route>
            <Route path="/contact-us">
              <Contact></Contact>
            </Route>
            <Route path="/update-system/best-inn">
              <BestInSys></BestInSys>
            </Route>
            <Route path="/blog">
              <Blog></Blog>
            </Route>
          </Switch>
        </Layout>
      </Router>
    </>
  );
}

export default App;