const AboutSys = () => {
    return (
        <>
            {/* Start slider */}
            <section className="slider-sec slider-pages">
                <div className="slider-item">
                    <img src="uploads/slider/sys.png" />
                </div>
            </section>
            {/* Start Breadcrumb */}
            <section className="breadcrumb-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a>الصفحة الرئيسية</a>
                                    </li>
                                    <span>&ensp;|&ensp; </span>
                                    <li className="breadcrumb-item active">تعرف على النظام</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Breadcrumb */}
            {/* Department */}
            <section className="dept-page-sec pad-50">
                <div className="container">
                    <div className="sec-title mb-3">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                {/* <h4>قسم النظام</h4> */}
                                <p />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="dept-carousel owl-carousel owl-theme mb-5  mt-1">
                                {/* <div class="item active"> */}
                                <div
                                    className="dept-block active "
                                    id="pills-14-tab"
                                    data-bs-target="pills-14"
                                >
                                    <div className="dept-icon">
                                        <img src="#" alt="تعريف نظام Birdsys " />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>تعريف نظام Birdsys </h5>
                                        <blockquote>
                                            نظام تخطيط موارد المؤسسات (Birdsys) هو نظام برمجيات يُستخدم في
                                            إدارة العمليات والموارد داخل المؤسسات والشركات بشكل متكامل.
                                            يهدف نظام...{" "}
                                        </blockquote>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* <div class="item active"> */}
                                <div
                                    className="dept-block  "
                                    id="pills-2-tab"
                                    data-bs-target="pills-2"
                                >
                                    <div className="dept-icon">
                                        <img
                                            src="../uploads/depart_system/Z9gOLEshC78Ylq2CPFskneRWwsNnp3OD.png"
                                            alt="Birdsys نظام المشتريات"
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>Birdsys نظام المشتريات</h5>
                                        <blockquote>
                                            المشترياتموديول المشتريات في نظام بيرد ( Birdsys ) يوفر العديد من
                                            المميزات، فهو يساعد على تنظيم عملية الشراء وإدارة الموردين...{" "}
                                        </blockquote>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* <div class="item active"> */}
                                <div
                                    className="dept-block  "
                                    id="pills-11-tab"
                                    data-bs-target="pills-11"
                                >
                                    <div className="dept-icon">
                                        <img
                                            src="../uploads/depart_system/gP75Ttv0LYLiahPtQwTNa4wpgO8yAI56.png"
                                            alt="Birdsys لـنظام المبيعات  "
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>Birdsys لـنظام المبيعات </h5>
                                        <blockquote>
                                            نظام المبيعات في نظام ( Birdsys ) يتميز بالعديد من المميزات،فهو
                                            يساعد على إدارة عمليات البيع الشراء بشكل متكامل وفعال.من...{" "}
                                        </blockquote>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* <div class="item active"> */}
                                <div
                                    className="dept-block  "
                                    id="pills-12-tab"
                                    data-bs-target="pills-12"
                                >
                                    <div className="dept-icon">
                                        <img
                                            src="../uploads/depart_system/ZCbnU8-5wWKPIB897I-QVRVIFeQk-VgL.png"
                                            alt="Birdsys لنظام المخازن والمستودعات "
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>Birdsys لنظام المخازن والمستودعات </h5>
                                        <blockquote>
                                            المخازننظام المخازن في نظام  ( Birdsys ) يوفر العديد من
                                            المميزات الهامة لإدارة المخزون بشكل فعال. من بين هذه...{" "}
                                        </blockquote>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* <div class="item active"> */}
                                <div
                                    className="dept-block  "
                                    id="pills-13-tab"
                                    data-bs-target="pills-13"
                                >
                                    <div className="dept-icon">
                                        <img
                                            src="../uploads/depart_system/0HSdVehvDd-RtRw_S3LHQDeyNbsYF0WS.png"
                                            alt="Birdsys نظام نقاط البيع POS"
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>Birdsys نظام نقاط البيع POS</h5>
                                        <blockquote>
                                            نقاط البيعموديول نقاط البيع POS في نظام  (Birdsys ) يوفر
                                            العديد من المزايا، فهو يسمح للمستخدمين بإدارة عمليات البيع...{" "}
                                        </blockquote>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* <div class="item active"> */}
                                <div
                                    className="dept-block  "
                                    id="pills-6-tab"
                                    data-bs-target="pills-6"
                                >
                                    <div className="dept-icon">
                                        <img
                                            src="../uploads/depart_system/pB2nMYgp6-IddxIhXeCTkzPALn-n7WRC.png"
                                            alt="Birdsys لنظام الحسابات "
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>Birdsys لنظام الحسابات </h5>
                                        <blockquote>
                                            الحسابات موديول الحسابات في نظام Birdsys يتميز بالعديد من
                                            المميزات، فهو يساعد على إدارة الحسابات والمالية بشكل متكامل
                                            وفعال. يتضمن...{" "}
                                        </blockquote>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane active " id="pills-14">
                                    <div className="sec-title">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <h4>ما هو تعريف نظام Birdsys </h4>
                                                <p />
                                            </div>
                                        </div>
                                    </div>
                                    <blockquote className="text-center">
                                        <p>
                                            نظام تخطيط موارد المؤسسات (Birdsys) هو نظام برمجيات يُستخدم في
                                            إدارة العمليات والموارد داخل المؤسسات والشركات بشكل متكامل.
                                            يهدف نظام Birdsys إلى تنظيم وتنسيق العديد من الوظائف والأقسام
                                            المختلفة داخل المؤسسة، مثل المبيعات والمشتريات والمخازن و
                                            التخطيط والإنتاج ونظام نقاط البيع والمحاسبة وإدارة الموارد
                                            البشرية وغيرها. يتيح النظام إدارة كل هذه العمليات من خلال
                                            واجهة واحدة متكاملة، مما يساعد على تحسين كفاءة العمل واتخاذ
                                            القرارات الاستراتيجية بشكل أفضل.
                                        </p>
                                        <p>
                                            <br />
                                        </p>
                                        <h1 />
                                        <h3>نظام المبيعات </h3>
                                        <p style={{ marginLeft: 140, textAlign: "justify" }}>
                                            - إدارة العملاء العملاء
                                            <br />- إدارة فريق المبيعات
                                            <br />- عروض الأسعار
                                            <br />- فواتير المبيعات ( الفوترة الالكترنية )<br />-
                                            الإشعارات الدائنة ( مرتجع الفواتير )<br />- سندات العملاء
                                            <br />- أوامر التسليم
                                            <br />- تقارير المبيعات
                                            <br />- الفواتير الضريبية
                                            <br />- الاقرار الضريبي
                                        </p>
                                        <h2 />
                                        <h3>
                                            نظام المشتريات <br />
                                        </h3>
                                        <p>
                                            الموردين
                                            <br />
                                            قوائم أسعار المورد
                                            <br />
                                            طلبات الشراء
                                            <br />
                                            فواتير المشتريات
                                            <br />
                                            فواتير المصروفات
                                            <br />
                                            الإشعارات المدينة
                                        </p>
                                        <p>سندات الموردين</p>
                                        <h3>المستودعات والمخازن </h3>
                                        <p>
                                            قائمة المستودعات
                                            <br />
                                            المواقع والأرفف
                                            <br />
                                            فئات المنتجات
                                            <br />
                                            قائمة المنتجات
                                            <br />
                                            وحدات القياس
                                            <br />
                                            تجميع الأصناف
                                            <br />
                                            تفكيك الأصناف
                                            <br />
                                            التسوية المخزنية
                                            <br />
                                            جرد المخزون
                                        </p>
                                        <h3>التخطيط والانتاج ( التصنيع ) </h3>
                                        <p>
                                            قوائم المواد
                                            <br />
                                            متغيرات المنتجات
                                            <br />
                                            المنتجات
                                            <br />
                                            تجميع الاصناف
                                            <br />
                                            تفكيك الاصناف
                                            <br />
                                            مخلفات التصنيع
                                        </p>
                                        <h3>نظام نقاط البيع </h3>
                                        <p>
                                            الطلبات
                                            <br />
                                            الجلسات
                                            <br />
                                            المدفوعات
                                            <br />
                                            العملاء
                                            <br />
                                            فئات المنتجات
                                            <br />
                                            المنتجات
                                            <br />
                                            تقارير الطلبات
                                            <br />
                                            تفاصيل المبيعات
                                            <br />
                                            طرق السداد
                                            <br />
                                            قوائم الأسعار
                                        </p>
                                        <h3>نظام الحسابات </h3>
                                        <p>
                                            شجرة الحسابات
                                            <br />
                                            مراكز التكلفة
                                            <br />
                                            القيود اليومية
                                            <br />
                                            التسوية البنكية
                                            <br />
                                            سندات الصرف
                                            <br />
                                            سندات القبض
                                            <br />
                                            الإشعارات الدائنة
                                            <br />
                                            الإشعارات المدينة
                                            <br />
                                            جرد الصندوق
                                            <br />
                                            سندات التحويل
                                        </p>
                                        <p>إدارة الموارد البشرية </p>
                                        <p>
                                            <br />
                                        </p>
                                    </blockquote>
                                    <div className="row mb-5"></div>
                                    <div className="accordion" id="accordionExample"></div>
                                </div>
                                <div className="tab-pane  " id="pills-2">
                                    <div className="sec-title">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <h4>ما هو Birdsys نظام المشتريات</h4>
                                                <p />
                                            </div>
                                        </div>
                                    </div>
                                    <blockquote className="text-center">
                                        <p>
                                            <strong>
                                                <span style={{ color: "rgb(0, 0, 0)" }} />
                                                <b>
                                                    <u>المشتريات</u>
                                                </b>
                                                <b>
                                                    <u />
                                                </b>
                                            </strong>
                                        </p>
                                        <p>
                                            موديول المشتريات في نظام بيرد ( Birdsys ) يوفر العديد من
                                            المميزات، فهو يساعد على تنظيم عملية الشراء وإدارة الموردين{" "}
                                        </p>
                                        <p>بشكل فعال.</p>
                                        <p>
                                            {" "}
                                            كما يتيح للمستخدمين إنشاء طلبات شراء وتتبعها وإصدار فواتير
                                            الشراء وإدارة المخزون. كذلك، يساعد في تحسين{" "}
                                        </p>
                                        <p>
                                            علاقة الشركة مع الموردين من خلال تحديثات دورية حول حالة
                                            الطلبات والمدفوعات. بالإضافة إلى ذلك، يتضمن موديول المشتريات
                                        </p>
                                        <p>
                                            {" "}
                                            في نظام Birdsys أدوات لإجراء التحقق من صحة الفواتير والتأكد من
                                            أنها تطابق الطلبات المقدمة.
                                        </p>
                                        <p>
                                            الموردين
                                            <br />
                                            قوائم أسعار المورد
                                            <br />
                                            طلبات الشراء
                                            <br />
                                            فواتير المشتريات
                                            <br />
                                            فواتير المصروفات
                                            <br />
                                            الإشعارات المدينة
                                            <br />
                                            سندات الموردين
                                        </p>
                                        <p>
                                            {" "}
                                            <br />
                                        </p>
                                    </blockquote>
                                    <div className="row mb-5">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                            <a
                                                className="btn btn-light custom-btn w-100 mb-2"
                                                href="../uploads/depart_system/e7bzOq4yLZ4gS6pOKLIphu--4o679hSH.pdf"
                                                 target="_blank" rel="noreferrer"
                                            >
                                                تحميل البروفايل{" "}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="accordion" id="accordionExample"></div>
                                </div>
                                <div className="tab-pane  " id="pills-11">
                                    <div className="sec-title">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <h4>ما هو Birdsys لـنظام المبيعات </h4>
                                                <p />
                                            </div>
                                        </div>
                                    </div>
                                    <blockquote className="text-center">
                                        <p>نظام المبيعات في نظام ( Birdsys ) يتميز بالعديد من المميزات،</p>
                                        <p>
                                            فهو يساعد على إدارة عمليات البيع الشراء بشكل متكامل وفعال.
                                        </p>
                                        <p>
                                            من بين المميزات التي يقدمها هذا الموديول: إنشاء عروض الأسعار
                                            وإصدار الفواتير
                                        </p>
                                        <p>
                                            ( مطابقة و معتمدة من هيئة الزكاة والضريبة والجمارك ) وإدارة
                                            الطلبات والشحنات، كما يتضمن أدوات لإدارة الأسعار والخصومات
                                            والضرائب.
                                        </p>
                                        <p>
                                            كذلك، يتضمن موديول المبيعات في نظام بيرد تقارير مفصلة حول
                                            عمليات البيع والشراء، ما يسهل على المستخدم إجراء التحليلات
                                            واتخاذ القرارات بشأن إدارة الأعمال.
                                        </p>
                                        <p>
                                            - إدارة العملاء العملاء
                                            <br />- إدارة فريق المبيعات
                                            <br />- عروض الأسعار
                                            <br />- فواتير المبيعات ( الفوترة الالكترنية )<br />-
                                            الإشعارات الدائنة ( مرتجع الفواتير )<br />- سندات العملاء
                                            <br />- أوامر التسليم
                                            <br />- تقارير المبيعات
                                        </p>
                                        <p>
                                            - الفواتير الضريبية <br />- الاقرار الضريبي
                                            <br />
                                        </p>
                                        <p>
                                            <br />
                                        </p>
                                    </blockquote>
                                    <div className="row mb-5"></div>
                                    <div className="accordion" id="accordionExample"></div>
                                </div>
                                <div className="tab-pane  " id="pills-12">
                                    <div className="sec-title">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <h4>ما هو Birdsys لنظام المخازن والمستودعات </h4>
                                                <p />
                                            </div>
                                        </div>
                                    </div>
                                    <blockquote className="text-center">
                                        <p>المخازن</p>
                                        <p>
                                            نظام المخازن في نظام  ( Birdsys ) يوفر العديد من المميزات
                                            الهامة لإدارة المخزون بشكل فعال.{" "}
                                        </p>
                                        <p>من بين هذه المميزات: </p>
                                        <p>التحكم في اضافة وادارة المنتجات </p>
                                        <p>تجميع المنتجات الاوليه والنهائية </p>
                                        <p>
                                            {" "}
                                            إدارة الجرد: يتيح لك الموديول إدارة جرد المخزون بشكل دقيق
                                            وفعال، مما يساعد على تحسين عملية التخطيط والتنبؤ بالاحتياجات.
                                        </p>
                                        <p>
                                            {" "}
                                            تتبع الحركة: يساعدك الموديول على تتبع حركة الأصناف داخل
                                            المخزون، مثل الإضافة والإزالة والتحويلات، مما يسهل عليك مراقبة
                                            حركة الأصناف وضمان توافرها في الأوقات المطلوبة.
                                        </p>
                                        <p>
                                            التحكم في التكاليف: يساعدك الموديول على تحديد تكاليف كل صنف في
                                            المخزون، مثل التكلفة الإجمالية وتكلفة الشراء وتكلفة التخزين،
                                            مما يسهل على إدارتك اتخاذ قرارات استراتيجية بشأن إدارة المخزون
                                        </p>
                                        <p>
                                            {" "}
                                            التقارير والإحصائيات: يقدم لك الموديل تقارير شاملة حول جرد
                                            المخزون وحركته، بالإضافة إلى إحصائيات حول أصناف المخزون
                                            وأسعارها.
                                        </p>
                                        <p>
                                            المستودعات
                                            <br />
                                            المواقع والأرفف
                                            <br />
                                            فئات المنتجات
                                            <br />
                                            قائمة المنتجات
                                            <br />
                                            وحدات القياس
                                            <br />
                                            تجميع الأصناف
                                            <br />
                                            تفكيك الأصناف
                                            <br />
                                            التسوية المخزنية
                                        </p>
                                    </blockquote>
                                    <div className="row mb-5"></div>
                                    <div className="accordion" id="accordionExample"></div>
                                </div>
                                <div className="tab-pane  " id="pills-13">
                                    <div className="sec-title">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <h4>ما هو Birdsys نظام نقاط البيع POS</h4>
                                                <p />
                                            </div>
                                        </div>
                                    </div>
                                    <blockquote className="text-center">
                                        <p>
                                            <b>
                                                <u>نقاط البيع</u>
                                            </b>
                                            <b>
                                                <u />
                                            </b>
                                        </p>
                                        <p>موديول نقاط البيع POS في نظام  (Birdsys ) </p>
                                        <p>
                                            يوفر العديد من المزايا، فهو يسمح للمستخدمين بإدارة عمليات
                                            البيع بشكل سهل وفعال.
                                        </p>
                                        <p> ويتضمن بعض المزايا التالية:</p>
                                        <p>
                                            سهولة الاستخدام: حيث يتم تصميم واجهة المستخدم بطريقة سهلة
                                            وبسيطة لتسهيل عملية البيع.
                                        </p>
                                        <p>
                                            اصدار فواتير الكترونية مطابقة لمواصفات هيئة الز كاة والضريبة
                                            والجمارك
                                        </p>
                                        <p>
                                            دقة في المخزون: حيث يتم تحديث المخزون تلقائيًا عند إجراء عملية
                                            بيع، مما يضمن دقة في إدارة المخزون.
                                        </p>
                                        <p>
                                            مرونة في الدفع: حيث يتضمن نظام POS خيارات دفع متعددة، مثل
                                            الكاش والبطاقات الائتمانية والشحن، مما يجعل عملية الشراء أسهل
                                            للزبائن.
                                        </p>
                                        <p>
                                            تقارير شاملة: حيث يتضمن نظام POS تقارير شاملة حول عملية البيع،
                                            مثل التقارير المالية وتقارير المخزون، مما يسهِّل على أصحاب
                                            الأعمال اتخاذ قرارات استراتيجية.
                                        </p>
                                        <p>
                                            الطلبات
                                            <br />
                                            الجلسات
                                            <br />
                                            المدفوعات
                                            <br />
                                            العملاء
                                            <br />
                                            فئات المنتجات
                                            <br />
                                            المنتجات
                                            <br />
                                            تقارير الطلبات
                                            <br />
                                            تفاصيل المبيعات
                                            <br />
                                            طرق السداد
                                            <br />
                                            قوائم الأسعار
                                        </p>
                                    </blockquote>
                                    <div className="row mb-5"></div>
                                    <div className="row mb-5 justify-content-center">
                                        <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                                            <a className=" openall btn btn-light custom-btn w-100 mb-2">
                                                افتح الكل
                                            </a>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                                            <a className="closeall  btn btn-light custom-btn w-100 mb-2">
                                                أغلق الكل
                                            </a>
                                        </div>
                                    </div>
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-12">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapse-12"
                                                    aria-expanded="false"
                                                    aria-controls="collapse-12"
                                                >
                                                    نظام البيع الخاص بالمطاعم{" "}
                                                </button>
                                            </h2>
                                            <div
                                                id="collapse-12"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="heading-12"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    <blockquote>
                                                        <p>صورة توضيحية لنظام نقاط البيع الخاص بالمطاعم POS </p>
                                                    </blockquote>
                                                    <div className="text-center p-5 ">
                                                        <div className="fqa-carousel owl-carousel owl-theme">
                                                            <div className="fqa-block">
                                                                <img
                                                                    src="../uploads/about_system/A2nGuSv4cWkT6SLOJh21utT6duxWkTYG.jpg"
                                                                    className=" image-faq mb-5"
                                                                />
                                                            </div>
                                                        </div>
                                                        <nav
                                                            className="nav nav-pills nav-fill action-nav addthis_toolbox"
                                                        >
                                                            <a
                                                                href="javascript:"
                                                                className=" nav-link addthis_button_print "
                                                            >
                                                                طباعة
                                                                <img src="../frontend/assets/image/icon_print.png" />
                                                            </a>
                                                            <a
                                                                href="javascript:"
                                                                className=" nav-link addthis_button_email"
                                                            >
                                                                مشاركة للبريد
                                                                <img src="../frontend/assets/image/icon_email.png" />
                                                            </a>
                                                            <a
                                                                href="javascript:"
                                                                className=" nav-link addthis_button_twitter"
                                                            >
                                                                مشاركة
                                                                <img src="../frontend/assets/image/icon_twiter.png" />
                                                            </a>
                                                            <a
                                                                href="javascript:"
                                                                className=" nav-link addthis_button_facebook"
                                                            >
                                                                مشاركة
                                                                <img src="../frontend/assets/image/icon_facebook.png" />
                                                            </a>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane  " id="pills-6">
                                    <div className="sec-title">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <h4>ما هو Birdsys لنظام الحسابات </h4>
                                                <p />
                                            </div>
                                        </div>
                                    </div>
                                    <blockquote className="text-center">
                                        <p>
                                            <b>
                                                <u>الحسابات </u>
                                            </b>
                                            <b>
                                                <u />
                                            </b>
                                        </p>
                                        <p>
                                            <b>
                                                <u />
                                            </b>
                                        </p>
                                        <p>موديول الحسابات في نظام Birdsys يتميز بالعديد من المميزات،</p>
                                        <p> فهو يساعد على إدارة الحسابات والمالية بشكل متكامل وفعال.</p>
                                        <p>
                                            {" "}
                                            يتضمن الموديول إنشاء الفواتير وإدارة الدفعات والمصاريف
                                            والإيرادات، كما يتيح إنشاء تقارير مالية مختلفة لتحليل الأرباح
                                            والخسائر.{" "}
                                        </p>
                                        <p>
                                            كما يسهل الموديول على المستخدمين تتبع حركة الأموال داخل
                                            الشركة، وإجراء التسوية بين الحسابات المختلفة.{" "}
                                        </p>
                                        <p>
                                            بشكل عام، يعد موديول الحسابات في نظام  أداة قوية لإدارة
                                            المالية في أي شركة.
                                        </p>
                                        <p>
                                            الإشعارات الدائنة
                                            <br />
                                            الإشعارات المدينة
                                            <br />
                                            جرد الصندوق
                                            <br />
                                            سندات الصرف
                                            <br />
                                            شجرة الحسابات
                                            <br />
                                            مراكز التكلفة
                                            <br />
                                            قيود اليومية
                                            <br />
                                            التسوية البنكية
                                            <br />
                                            سندات التحويل
                                            <br />
                                            سندات القبض
                                        </p>
                                        <p>
                                            <br />
                                        </p>
                                    </blockquote>
                                    <div className="row mb-5"></div>
                                    <div className="accordion" id="accordionExample"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Department */}
        </>


    );
}

export default AboutSys;