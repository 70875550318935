import Footer from "./component/Footer";
import Navbar from "./component/Navbar";

const Layout = ({ children }) => {
    return (
        <>
            <Navbar></Navbar>
            <div className="site-content">
                {children}
            </div>
            <Footer></Footer>
        </>
    );
}

export default Layout;