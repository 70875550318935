const Faq = () => {
    return (
        <>
            {/* Start slider */}
            <section className="slider-sec slider-pages">
                <div className="slider-item">
                    <img src="../uploads/slider/faq.png" alt="image" />
                </div>
            </section>
            {/* Start Breadcrumb */}
            <section className="breadcrumb-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/">الصفحة الرئيسية</a>
                                    </li>
                                    <span>&ensp;|&ensp; </span>
                                    <li className="breadcrumb-item active">الاسئلة الشائعة</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Breadcrumb */}
            {/* Start FQA Section */}
            <section className="fqa-sec pad-50">
                <div className="container">
                    <div className="sec-title">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                <h4>الاسئلة الشائعة</h4>
                                <p />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading-12">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-12"
                                            aria-expanded="false"
                                            aria-controls="collapse-12"
                                        >
                                            ما هي أنواع التقارير في نظام Birdsys؟{" "}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapse-12"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="heading-12"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <blockquote>
                                                <p>يتميز نظام </p>
                                            </blockquote>
                                            <div className="text-center p-5 ">
                                                <div className="fqa-carousel owl-carousel owl-theme"></div>
                                                <nav
                                                    className="nav nav-pills nav-fill action-nav addthis_toolbox"
                                                >
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_print "
                                                    >
                                                        طباعة
                                                        <img src="../frontend/assets/image/icon_print.png" alt="image"  />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_email"
                                                    >
                                                        مشاركة للبريد
                                                        <img src="../frontend/assets/image/icon_email.png" alt="image"  />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_twitter"
                                                    >
                                                        مشاركة
                                                        <img src="../frontend/assets/image/icon_twiter.png"  alt="image" />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_facebook"
                                                    >
                                                        مشاركة
                                                        <img src="../frontend/assets/image/icon_facebook.png" alt="image"  />
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading-13">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-13"
                                            aria-expanded="false"
                                            aria-controls="collapse-13"
                                        >
                                            هل تم ربط نظام Birdsys مع منصة فاتورة المرحلة الثانية ( الربط
                                            والتكامل ) ؟
                                        </button>
                                    </h2>
                                    <div
                                        id="collapse-13"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="heading-13"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <blockquote>
                                                <p>
                                                    نعم تم عمل الربط بين نظام Birdsys ومنصة فاتورة التابعة لهيئة
                                                    الزكاة والضريبه والجمارك مرحلة الربط والتكامل{" "}
                                                </p>
                                                <p>حيث تم التحقق من صيغة الفاتورة دون وجود اي اخطاء </p>
                                            </blockquote>
                                            <div className="text-center p-5 ">
                                                <div className="fqa-carousel owl-carousel owl-theme">
                                                    <div className="fqa-block">
                                                        <img
                                                        alt="image" 
                                                            src="../uploads/faq/xFWKTWn28dUn0-XOatjDjvmU_pKp7Tnk.jpg"
                                                            className=" image-faq mb-5"
                                                        />
                                                    </div>
                                                </div>
                                                <nav
                                                    className="nav nav-pills nav-fill action-nav addthis_toolbox"
                                                >
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_print "
                                                    >
                                                        طباعة
                                                        <img src="../frontend/assets/image/icon_print.png" alt="image" />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_email"
                                                    >
                                                        مشاركة للبريد
                                                        <img src="../frontend/assets/image/icon_email.png"alt="image"  />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_twitter"
                                                    >
                                                        مشاركة
                                                        <img src="../frontend/assets/image/icon_twiter.png" alt="image" />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_facebook"
                                                    >
                                                        مشاركة
                                                        <img src="../frontend/assets/image/icon_facebook.png" alt="image" />
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading-4">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-4"
                                            aria-expanded="false"
                                            aria-controls="collapse-4"
                                        >
                                            هل النظام معتمد من هيئة الزكاة والضريبة والجمارك؟{" "}
                                        </button>
                                    </h2>
                                    <div
                                        id="collapse-4"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="heading-4"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <blockquote>
                                                <p>
                                                    نظام Birdsys ، هو نظام معتمد من هيئة الزكاة والضريبة
                                                    والجمارك، ومطابق تماما لمتطلبات الهيئة لنظام الفوترة
                                                    الإلكترونية والقوانين الخاصة بالفاتورة الضريبية.
                                                </p>
                                                <p>
                                                    يضمن لك النظام عدم المخالفة لمتطلبات الهيئة المنصوص عليها
                                                    بتطبيق الفوترة الإلكترونية أو الفاتورة الضريبية، ويمكنك
                                                    أيضا التأكد بالرجوع لموقع الهيئة والتأكد من إدارج النظام
                                                    ضمن الBirdsysج المعتمدة.
                                                </p>
                                            </blockquote>
                                            <div className="text-center p-5 ">
                                                <div className="fqa-carousel owl-carousel owl-theme">
                                                    <div className="fqa-block">
                                                        <img alt="image" 
                                                            src="../uploads/faq/5Dgue6cMUeLfM9fPWgctkyGUIwlTEQBu.jpg"
                                                            className=" image-faq mb-5"
                                                        />
                                                    </div>
                                                </div>
                                                <nav
                                                    className="nav nav-pills nav-fill action-nav addthis_toolbox"
                                                >
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_print "
                                                    >
                                                        طباعة
                                                        <img src="../frontend/assets/image/icon_print.png" alt="image"  />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_email"
                                                    >
                                                        مشاركة للبريد
                                                        <img src="../frontend/assets/image/icon_email.png" alt="image"  />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_twitter"
                                                    >
                                                        مشاركة
                                                        <img src="../frontend/assets/image/icon_twiter.png" alt="image"  />
                                                    </a>
                                                    <a
                                                        href="javascript:"
                                                        className=" nav-link addthis_button_facebook"
                                                    >
                                                        مشاركة
                                                        <img src="../frontend/assets/image/icon_facebook.png" alt="image"  />
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>


    );
}

export default Faq;

